<template>
  <!-- profile info  -->
  <section id="profile-info">
    <b-row>
      <!-- about suggested page and twitter feed -->
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="userData.instansi_id" />
        <profile-side-menu />
      </b-col>
      <!--/ about suggested page and twitter feed -->

      <!-- post -->
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <profile-table />
      </b-col>
      <!-- post -->

    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

import ProfileAbout from '../pengajuan/module/PengajuanAbout.vue'
import ProfileSideMenu from '../pengajuan/module/PengajuanSideMenu.vue'
import ProfileTable from './TabelSurat.vue'

export default {
  components: {
    BRow,
    BCol,

    ProfileAbout,
    ProfileSideMenu,
    ProfileTable,
  },
  beforeRouteEnter(to, from, next) {
    const role = localStorage.getItem('roleId')
    if (role > 110 || role < 99) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      userData: getUserData(),
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
